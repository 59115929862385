<template>
  <div class="home-container" v-if="isAccountOwner || isInstituteAdmin || isTeacher">
    <GuruBreadcrumb :link-list="linkList"/>
    <div class="has-text-centered guru-ins-title" v-if="currentAssociation.institute">User Management</div>
    <div class="has-text-centered">
      <button class="button is-info has-text-white has-text-weight-semibold" @click="addUser">Add User</button> &nbsp;&nbsp;
      <router-link class="button" :to="insHome">Close</router-link>
    </div>
    <div class="has-text-centered bulk-coming-soon">*User Bulk Creation functionality coming soon*</div>
    <div class="has-text-centered users-onboard-hint-section">
      <a class="is-link is-size-7 has-text-weight-semibold has-text-centered is-underlined" v-show="!showHowToOnBoard" @click="showHowToOnBoard=true">Show How to Onboard User</a>
      <a class="is-link is-size-7 has-text-weight-semibold has-text-centered is-underlined" v-show="showHowToOnBoard" @click="showHowToOnBoard=false">Hide How to Onboard User</a>
      <div class="has-text-left box how-to-on-board-box has-background-white-ter" v-show="showHowToOnBoard">
        <ol>
          <li>Create a new user using the button above</li>
          <li>If the user has not signed up with JDoodle already, ask the user to sign up with JDoodle.com using the same Email ID you used when creating the user</li>
          <li>After signing up with JDoodle, ask the user to visit -  <a href="https://www.jdoodle.com/guru">https://www.jdoodle.com/guru</a></li>
          <li>Under "Your Virtual Institutes" section, they can accept the admission</li>
        </ol>
      </div>
    </div>

    <div class="columns has-text-centered" v-show="users.length>0">
      <div class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
        <table class="table is-striped is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th>User</th>
            <th>Name</th>
            <th>Role(s)</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users">
            <td><a class="btn btn-link" @click="viewUser(user)">{{user.email}}</a></td>
            <td>{{user.firstName}} {{user.lastName}}</td>
            <td><div v-show="user.isAccountOwner">Account Owner</div><div v-for="role in user.roles">{{role}}</div></td>
            <td>{{user.status}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import GuruBreadcrumb from './GuruBreadcrumb'
import { eventBus } from '../../assets/javascript/event-bus'
import formMixin from '../../assets/javascript/form-mixin'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'guruUsers',
  components: { GuruBreadcrumb },
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'guruInitialised'],
  inject: ['$validator'],
  mixins: [formMixin],
  mounted () {
    eventBus.$on(GURU_EVENTS.ADD_TO_USERS_LIST, (user) => {
      this.users.push(user)
      this.sortUsers()
    })

    eventBus.$on(GURU_EVENTS.REMOVE_FROM_USERS_LIST, (email) => {
      let idx = window._.findIndex(this.users, (o) => { return o.email === email })
      this.users.splice(idx, 1)
    })

    eventBus.$on(GURU_EVENTS.REPLACE_IN_USERS_LIST, (user) => {
      let idx = window._.findIndex(this.users, (o) => { return o.email === user.email })
      this.users.splice(idx, 1)
      this.users.push(user)
      this.sortUsers()
    })

    this.initUsers()
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.ADD_TO_USERS_LIST)
    eventBus.$off(GURU_EVENTS.REMOVE_FROM_USERS_LIST)
    eventBus.$off(GURU_EVENTS.REPLACE_IN_USERS_LIST)
  },
  data: function () {
    return {
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        },
        {
          title: 'Institute Home',
          href: '/guru/ins/' + this.$route.params.instituteCode
        }
      ],
      users: [],
      showHowToOnBoard: false
    }
  },
  methods: {
    initUsers () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/users',
        data:
          {
            'instituteCode': this.$route.params.instituteCode
          },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.users = data.users
          this.sortUsers()
        },
        jdaEvent: 'get-users',
        jdaLabel: ''
      })
    },
    sortUsers () {
      this.users = window._.sortBy(this.users, (o) => { return o.firstName + ' ' + o.lastName })
    },
    addUser () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruUser.vue'),
        { mode: 'add',
          instituteCode: this.$route.params.instituteCode,
          isAccountOwner: this.isAccountOwner,
          isInstituteAdmin: this.isInstituteAdmin,
          isTeacher: this.isTeacher })
    },
    viewUser (user) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruUser.vue'),
        { mode: 'view',
          instituteCode: this.$route.params.instituteCode,
          email: user.email,
          isAccountOwner: this.isAccountOwner,
          isInstituteAdmin: this.isInstituteAdmin,
          isTeacher: this.isTeacher })
    },
    checkAuthentication () {
      if (!this.guruInitialised) {
        return
      }

      let location = '/guru/ins/' + this.$route.params.instituteCode

      if (!this.isAccountOwner && !this.isInstituteAdmin && !this.isTeacher) {
        this.$router.push(location)
      }
    }
  },
  computed: {
    insHome () {
      return '/guru/ins/' + this.$route.params.instituteCode
    }
  },
  watch: {
    guruInitialised () {
      this.checkAuthentication()
    },
    currentAssociation () {
      this.checkAuthentication()
    }
  }
}
</script>

<style lang="scss">
  .guru-ins-title {
    font-size: 1.2em;
    color: rgb(88, 88, 90);
    font-weight: bold;
    margin-bottom: 20px;
  }

  .bulk-coming-soon {
    font-size: 0.75em;
    font-weight: bold;
    margin: 5px 0px 0px 0px;
  }

  .how-to-on-board-box {
    margin: 0px 5%;
    padding: 5px 20px;
    font-size: 0.8em;
    font-weight: 500;
    ol {
      li {
        padding: 5px;
      }
    }
  }

  .users-onboard-hint-section {
    margin-bottom: 10px;
  }
</style>
