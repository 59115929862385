<template>
  <div class="guru-breadcrumb">
    <span v-for="link in linkList" v-bind:key="link.title">
      <router-link :to="link.href">{{link.title}}</router-link> &gt;&gt;
    </span>
    <span class="has-text-weight-bold uname" > {{$store.state.username}} </span>
  </div>
</template>
<script>
export default {
  name: 'guruBreadcrumb',
  props: ['linkList']
}
</script>

<style lang="scss">
  .guru-breadcrumb {
    margin-left: 5px;
    padding: 2px;
    font-size: 0.8em;
  }

  .uname {
    position: absolute;
    right: 10px;
  }
</style>
